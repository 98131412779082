import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

export const JOIN_US = "join-us";

const CtaJoinUs = () => {
  return (
    <AnchorLink href={`#${JOIN_US}`} title="Dołącz do nas" className="js-cta-join-us-campaign">
      Dołącz do nas
    </AnchorLink>
  );
};

export default CtaJoinUs;
