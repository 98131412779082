import React from "react";

import Logo1 from "./logo1.png";
import Logo2 from "./logo2.png";
import Logo3 from "./logo3.png";
import Logo4 from "./logo4.png";
import Logo5 from "./logo5.png";

import "./CampaignFooter.scss";
import SectionWrapper from "../components/SectionWrapper";
import { Link } from "gatsby";

const divider = <div className="campaign-footer__divider" />;
const logos = [
  { src: Logo1, alt: "Twoje Znamiona" },
  { src: divider, alt: "divider" },
  { src: Logo2, alt: "Fundusze Europejskie dla Rozwoju Spolecznego" },
  { src: Logo3, alt: "rzeczpospolita Polska" },
  { src: Logo4, alt: "Dofinansowane przez Unie Europejska" },
  { src: divider, alt: "divider" },
  { src: Logo5, alt: "Narodowe Centrum Badan i Rozwoju" },
];

const CampaignFooter = () => {
  return (
    <footer className="campaign-footer">
      <SectionWrapper>
        <div className="campaign-footer__wrapper">
          <section className="campaign-footer__section">
            <h3 className="campaign-footer__title">Organizator kampanii</h3>
            <p className="campaign-footer__description">
              <Link
                to={"/projekt-dofinansowany-z-funduszy-europejskich"}
              >
                Projekt dofinansowany z Funduszy Europejskich pt. "Twoje znamiona"
              </Link>
            </p>
          </section>
          <div className="campaign-footer__logos">
            {logos.map(({ src, alt }, i) => {
              return alt === "divider" ? (
                <React.Fragment key={i}>{src}</React.Fragment>
              ) : (
                <img key={i} src={src} alt={alt} />
              );
            })}
          </div>
        </div>
      </SectionWrapper>
    </footer>
  );
};

export default CampaignFooter;
