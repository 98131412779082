import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/youtube";

import PlayButton from "../../../PlayButton";

import ModalClose from "./modal-close.svg";
import "./HeroVideo.scss";

type HeroVideoProps = {
  url: string;
  heroPreview: string;
  getIsPlaying?: React.Dispatch<React.SetStateAction<boolean>>;
  isModal?: boolean;
};

const HeroVideo = ({
  url,
  heroPreview,
  getIsPlaying,
  isModal,
}: HeroVideoProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    if (isClosed) {
      setIsClosed(false);
    }
  }, [isClosed]);

  useEffect(() => {
    if (getIsPlaying) {
      getIsPlaying(isPlaying);
    }
  }, [isPlaying, getIsPlaying]);

  const closeModal = () => {
    setIsPlaying(false);
    setIsClosed(true);
  };

  const reactPlayer = (
    <ReactPlayer
      ref={ref}
      playing={isPlaying}
      url={url}
      height={"100%"}
      width={"100%"}
      controls={false}
      light={heroPreview}
      playIcon={<PlayButton />}
      onEnded={() => {
        setIsPlaying(false);
        ref.current?.showPreview();
      }}
      onClickPreview={() => {
        setIsPlaying(true);
      }}
    />
  );

  return isModal && !isClosed ? (
    <div
      onClick={closeModal}
      className={`playing-modal ${isPlaying ? "playing-modal--playing" : ""}`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`playing-modal__container ${
          isPlaying ? "playing-modal__container--playing" : ""
        }`}
      >
        {isPlaying && (
          <button onClick={closeModal} className="playing-modal__close-btn">
            <img src={ModalClose} alt="close modal" />
          </button>
        )}
        <div className="hero-video">
          <div
            className={`hero-video__video-size ${
              isPlaying ? "hero-video__video-size--playing" : ""
            }`}
          >
            {reactPlayer}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="hero-video">
      <div
        className={`hero-video__video-size ${
          isPlaying ? "hero-video__video-size--playing" : ""
        }`}
      >
        {reactPlayer}
      </div>
    </div>
  );
};

export default HeroVideo;
