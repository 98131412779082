import React from "react";
import "./SectionWrapper.scss";

const SectionWrapper = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactElement | React.ReactElement[];
}) => {
  return (
    <div className={`${className ? className : ""} section-wrapper`}>
      {children}
    </div>
  );
};

export default SectionWrapper;
