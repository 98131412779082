import React, { useState, useEffect, useCallback } from "react";
import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import hamburgerIcon from "../../../img/hamburger_menu.svg";
import CloseIcon from "../../../img/close.svg";
import { Helmet } from "react-helmet";
import Button from "../../Button";

import campaignLogo from "./campaign-logo.svg";
import "./CampaignHeader.scss";
import CtaJoinUs from "../CtaJoinUs";

export const navItems = {
  about: {
    link: "o-kampanii",
    label: "O kampanii",
  },
  ambassadors: {
    link: "ambasadorzy",
    label: "Ambasadorzy",
  },
  partners: {
    link: "partnerzy",
    label: "Partnerzy",
  },
  worthToKnow: {
    link: "warto-wiedziec",
    label: "Warto wiedzieć",
  },
};
export const LOGO_LINK = "nie-przekonuj-sie";
const BASE_URL = "nie-przekonuj-sie-na-wlasnej-skorze";
const aboutLink = `/${BASE_URL}/${navItems.about.link}`;

const resolveNavItems = (isAboutPage) => {
  return Object.entries(navItems).map(([item, { link, label }]) => {
    switch (item) {
      case "about":
        return isAboutPage ? (
          <AnchorLink
            href={`#${link}`}
            title={label}
            className="campaign-header__anchor-link"
          >
            {label}
          </AnchorLink>
        ) : (
          <Link className="campaign-header__anchor-link" to={aboutLink}>
            {label}
          </Link>
        );
      default:
        return isAboutPage ? (
          <Link
            to={`/${BASE_URL}/#${link}`}
            className="campaign-header__anchor-link"
          >
            {label}
          </Link>
        ) : (
          <AnchorLink
            href={`#${link}`}
            title={label}
            className="campaign-header__anchor-link"
          >
            {label}
          </AnchorLink>
        );
    }
  });
};

const Logo = ({ isAboutPage }) => {
  return isAboutPage ? (
    <Link to={`/${BASE_URL}`}>
      <img src={campaignLogo} alt="Nie przekonuj sie na slasnej skorze" />
    </Link>
  ) : (
    <AnchorLink
      href={`#${LOGO_LINK}`}
      title="Logo"
      className="campaign-header__logo"
    >
      <img src={campaignLogo} alt="Nie przekonuj sie na slasnej skorze" />
    </AnchorLink>
  );
};

const CampaignNavigation = ({ location }) => {
  const isAboutPage = location.pathname === aboutLink;
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpened(false);
  }, []);

  useEffect(() => {
    const closeMenu = (e: any) => {
      const innerWidth = e.target.innerWidth;
      const SCREEN_MD = 896;
      const shouldCloseMenu = innerWidth > SCREEN_MD && isMenuOpened;

      if (shouldCloseMenu) {
        handleCloseMenu();
      }
    };

    window.addEventListener("resize", closeMenu);

    return () => {
      window.removeEventListener("resize", closeMenu);
    };
  }, [isMenuOpened, handleCloseMenu]);

  return (
    <header
      className={`campaign-header ${
        isAboutPage ? "campaign-header--about" : ""
      }`}
      role="navigation"
      aria-label="main-navigation"
    >
      <Logo isAboutPage={isAboutPage} />
      <nav className={`campaign-header__navigation`}>
        <ul
          className={`campaign-header__navigation-items ${
            isMenuOpened ? "campaign-header__navigation-items--opened" : ""
          }`}
        >
          {resolveNavItems(isAboutPage).map((item, i) => {
            return (
              <li
                key={i}
                className={"campaign-header__navigation-item"}
                onClick={handleCloseMenu}
              >
                {item}
              </li>
            );
          })}
          <li className="navigation-contact" onClick={handleCloseMenu}>
            <Button
              size="M"
              className="normal-button campaign-header__join-btn"
            >
              <CtaJoinUs />
            </Button>
            <Button size="S" className="small-button campaign-header__join-btn">
              <CtaJoinUs />
            </Button>
          </li>
        </ul>
      </nav>

      <div
        className="navigation-items-mobile"
        onClick={() => setIsMenuOpened(!isMenuOpened)}
      >
        {!isMenuOpened && (
          <img className="hamburgerIcon" src={hamburgerIcon} alt="Menu" />
        )}
        {isMenuOpened && (
          <img className="closeIcon" src={CloseIcon} alt="Zamknij Menu" />
        )}
      </div>
    </header>
  );
};

export default CampaignNavigation;
