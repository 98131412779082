import React from "react";
import "./CampaignLogoTitle.scss";

const CampaignLogoTitle = () => {
  return (
    <span className="logo-title">
      #na
      <span className="logo-title__own">własnej</span>
      <span className="logo-title__skin">skórze</span>
    </span>
  );
};

export default CampaignLogoTitle;
