import React from "react";
import CampaignLogoTitle from "../../CampaignLogoTitle";
import Button from "../../../Button";
import { JOIN_US } from "../../CtaJoinUs";

import "./CampaignJoin.scss";
import CampaignSection from "../../components/CampaignSection";
import { Link } from "gatsby";

const CampaignJoin = () => {
  return (
    <CampaignSection
      id={JOIN_US}
      className="campaign-join"
      title={
        <>
          Dołącz do grona osób wspierających naszą kampanię{" "}
          <CampaignLogoTitle />!
        </>
      }
      paragraph={
        <>
          Opublikuj{" "}
          <Link to="https://www.youtube.com/watch?v=pNUCmeqpyM4" target="_blank">
            link do spotu kampanii
          </Link>{" "}
          w swoich profilach social media. lub udostępnij bezpośrednio z naszego{" "}
          <Link to="https://www.instagram.com/nieprzekonujsienawlasnejskorze/" target="_blank">
            profilu na Instagramie
          </Link>
        </>
      }
    >
      <Button className="campaign-join__download-btn">
        <a
          href="https://docs.google.com/document/d/1OJES5cNCxlZJ64p8epUy65JnJFIKa-1Puam1LFnOWQw/edit?usp=sharing"
          target="__blank"
        >
          Pobierz materiały
        </a>
      </Button>
    </CampaignSection>
  );
};

export default CampaignJoin;
