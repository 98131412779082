import React from "react";
import SectionDescription from "../SectionDescription";
import "./CampaignSection.scss";
import SectionWrapper from "../SectionWrapper";

const CampaignSection = ({
  id,
  className,
  title,
  subtitle,
  paragraph,
  children,
}: {
  id?: string;
  className?: string;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  paragraph?: string | React.ReactElement;
  children?: React.ReactElement | React.ReactElement[];
}) => {
  return (
    <SectionWrapper className={className}>
      <div id={id} className={`${className}__section campaign-section`}>
        <div className={`${className}__wrapper campaign-section__wrapper`}>
          <SectionDescription
            className={`${className} campaign-section`}
            title={title}
            subtitle={subtitle}
            paragraph={paragraph}
          />
          {children && (
            <div className={`${className}__cards campaign-section__cards`}>
              {children}
            </div>
          )}
        </div>
      </div>
    </SectionWrapper>
  );
};

export default CampaignSection;
