import React from "react";
import CampaignMetaWrapper from "../CampaignMetaWrapper";
import CampaignNavigation from "../CampaignHeader";
import CampaignFooter from "../CampaignFooter";
import "./CampaignLayout.scss";

const CampaignLayout = ({ location, children }) => {
  return (
    <CampaignMetaWrapper location={location}>
      <div className="campaign-layout">
        <CampaignNavigation location={location} />
        <main className="campaign-layout__main">{children}</main>
        <CampaignFooter />
      </div>
    </CampaignMetaWrapper>
  );
};

export default CampaignLayout;
