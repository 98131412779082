import React from "react";
import "./SectionDescription.scss";

const addOuterClasses = (classes, elementClass) => {
  return (
    classes?.map((className) => `${className}__${elementClass}`).join(" ") || ""
  );
};

const SectionDescription = ({
  className,
  title,
  subtitle,
  paragraph,
  children,
}: {
  className?: string;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  paragraph?: string | React.ReactElement;
  children?: React.ReactElement;
}) => {
  const classesArr = className?.split(" ");

  return (
    <section
      className={`${addOuterClasses(
        classesArr,
        "description"
      )} section-description`}
    >
      {subtitle && (
        <h6
          className={`${addOuterClasses(
            classesArr,
            "subtitle"
          )} section-description__subtitle`}
        >
          {subtitle}
        </h6>
      )}
      {title && (
        <h2
          className={`${addOuterClasses(
            classesArr,
            "title"
          )} section-description__title`}
        >
          {title}
        </h2>
      )}
      {paragraph && (
        <p
          className={`${addOuterClasses(
            classesArr,
            "paragraph"
          )} section-description__paragraph`}
        >
          {paragraph}
        </p>
      )}
      {children}
    </section>
  );
};

export default SectionDescription;
